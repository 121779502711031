.login-container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-error{
  display: block;
}

.login-form {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 300px;
}

.form-floating {
  margin-bottom: 15px;
}

.login-logo{
  width: 100px;
  height: 100px;
}