.modal-background-1 {
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    color: black;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000; 
    margin: 0 auto 0;
    overflow-y: hidden;
    padding-top: 2000px;
    padding-bottom: 2000rem;
}

.card-over1{
    padding-top: 900px;
    padding-bottom: 200px;
}

.card-over2{
    padding-top: 900px;
    padding-bottom: 200px;
}

@media screen and (max-width:991px){
    .modal-background-1 {
        width: 100%;
        height: 100%;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        color: black;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 1000; 
        margin: 0 auto 0;
        overflow-y: auto;
        margin-top: 0px;
        margin-bottom: 3000px;
        padding-top: 2000rem;
        padding-bottom: 2000rem;
    }
    
}