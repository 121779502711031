.navbar{  
  display: flex;
  position: fixed;
  width: 100%;
  height: 90px;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  z-index: 10;
  top: 0;
  transition: .5s;
}


.navbar-bg{
  background-color: #212529;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: white;
}

.navbar #nav-link-hover{
  color: var(--white-color);
}

#nav-link-hover:hover{
  color: var(--primary);
}


.nav-link{
  font-family: 'Poppins';
}

.logo {
  max-width: 50px; /* A logo maximális szélessége */
  height: auto; /* Automatikus magasság */
}

.social-icons {
  display: flex;
  align-items: center;
}

.social-icon:hover{
  color: #ad2323;
}

.nav-links{
  color: white;
}

.navbar-like:hover{
  color:#ad2323;
}

.menu-icons{
  color: #fff;
}

.social-icons a {
  color: #fff;
  font-size: 1.5rem;
  margin-left: 10px;
}

.navbar-logo-img{
  width: 150px;
  height: 100%;
}

.nav-item {
  position: relative;
  color: #fff;
  cursor: pointer;
}

.nav-item::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 60px;
  height: 3px;
  background-color: transparent;
  transform: translateX(-50%);
  transition: background-color 0.3s ease;
  z-index: 1;
}

.nav-item:hover::after {
  background-color: #BF0426 ;
  color: #BF0426 ;
}

.dropdown-item:hover {
  background-color: var(--primary) !important;
}

@media screen and (max-width:1240px){
  .navbar{
    height: 90px;
    background-color: none;
  }

  .navbar-bg {
      background-color: #212529;
      transition: .5s;
  }

  .social-icons{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navbar-nav{
    text-align: center;
  }
}

@media screen and (max-width:991px){
  .navbar{
    height: 90px;
    background-color: none;
  }

  .navbar-bg {
      background-color: #212529;
      transition: .5s;
  }

  .navbar-collapse{
    background-color: #212529;
    height: 100%;
  }

  .social-icons{
    background-color: #212529;
    padding: 6px;
  }

  .social-icons{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navbar-nav{
    text-align: center;
  }

  .social-icons{
    padding: 0px 10px 10px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px 0px 15px 15px;
    transition: .5s;
  }

  .dropdown-li{
    text-align: center;
  }

  .dropdown-item{
    text-align: center;
  }
}
