.dashhome-title{
    font-family: "Poppins";
    font-weight: 700;
    color: #1a1a1a;
    text-align: center;
}

.dashhome-winlogin{
    font-family:'Rubik';
    font-weight: 700;
    color: green;
    text-align: center;
}