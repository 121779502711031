.cardetail{
    display: flex;
    margin: 0 auto 0;
    justify-content: center;
    overflow: hidden;
}

.cardetail-name{
    color: #1a1a1a;
    font-family: 'Poppins';
    font-weight: 700;
}

.cardetail-description{
    color: #1a1a1a;
    font-family: "Rubik";
    font-weight: 400;
}

.cardetail-day-price{
    color: #1a1a1a;
    font-family: 'Poppins';
    font-weight: 700;
}

.cardetail-been{
    color: #1a1a1a;
    font-family: "Rubik";
    font-weight: 400;
    margin-right: 10px;
}

.cardetail-day-month{
    color: #1a1a1a;
    font-family: 'Poppins';
    font-weight: 700;
}

.cardetail-day-saleat{
    color: #1a1a1a;
    font-family: 'Poppins';
    font-weight: 700;
}

.cardetail-list{
    color: #1a1a1a;
    font-family: "Rubik";
    font-weight: 400;
}

.cardetail-data-left{
    color: #1a1a1a;
    font-family: 'Poppins';
    font-weight: 700;
}

.cardetail-data-right{
    color: #1a1a1a;
    font-family: "Rubik";
    font-weight: 400;
}

.cardetail-information{
    color: #1a1a1a;
    font-family: 'Poppins';
    font-weight: 700;
}

.cardetail-booking{
    display: flex;
    align-items: center;
    justify-content: center;
}