.footer{
    background-color: #2a2e32;
    display: block;
}

.footer-title{
    font-family: 'Poppins';
    font-weight: 700;
    color: #fff;
}

.footer-policy{
    font-family: 'Rubik';
    font-weight: 400;
}
  
.footer-contact{
    font-family: 'Rubik';
    font-weight: 400;
    color: #817F91;
}

.small {
    font-size: 0.9rem;
    font-family: 'Rubik';
    font-weight: 400;
    color: #817F91;
}

.footer-links{
    text-decoration: none;
    color: #fff;
    z-index: 1;
}

.footer-links:hover{
    text-decoration: none;
    color: #fff;
    z-index: 1;
    opacity: 65%;
}

.socialsicons{
    justify-content: start;
}


.footer-link {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
    color: white;
}

.footer-link:hover, .footer-link:focus {
    text-decoration: none;
}


.form-control:focus {
    background: #212529;
}

footer {
    background: #212529;
}
  
@media screen and (max-width:991px) {
    .footer-row{
        text-align: center;
    }
    .footer-flex{
        margin: 0 auto 0;
        text-align: center;
    }
    .socialsicons{
        justify-content: center;
    }
}