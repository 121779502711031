.cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: center;
    z-index: 9999; /* A felső rétegek felett jelenjen meg */
}
  
.accept-button,
.reject-button {
    background-color: #0d6efd;
    color: #fff;
    border: none;
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
}
  
.reject-button {
    background-color: #dc3545;
}

.policy-button{
    background-color: #0d6efd;
    color: #fff;
    border: none;
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
}

@media screen and (max-width: 991px) {
    .policy-button {
        display: inline-block;
        margin-top: 5px ; /* Show the policy button on screens with a width of 991px or less */
    }
}