@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,600;0,700;1,600;1,700&family=Lato:ital,wght@0,700;1,700&family=Poppins:wght@700&family=Rubik&display=swap');

:root{
  --primary: #0d6efd;
  --hover-primary: #0057A8;
  --white-color: #ffffff ;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-unique {
  text-decoration: none;
  display: inline-block;
  padding: .75rem 1.55rem;
  border-radius: 10px;
  color: var(--white-color);
  text-transform: uppercase;
  font-size: 1rem;
  font-family: 'Poppins';
  font-weight: 700;
  letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: none;
  background-color: var(--primary) ;
}

.button-unique:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:var(--primary) ;
  border-radius: 10px;
  z-index: -2;
}

.button-unique:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: var(--hover-primary) ;
  transition: all .3s;
  border-radius: 10px;
  z-index: -1;
}

.button-unique:hover {
  color: var(--white-color);
}

.button-unique:hover:before {
  width: 100%;
}

.button-unique-2 {
  text-decoration: none;
  display: inline-block;
  padding: .75rem 1.55rem;
  border-radius: 10px;
  color: var(--white-color);
  text-transform: uppercase;
  font-size: 1rem;
  font-family: 'Poppins';
  font-weight: 700;
  letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: none;
  background-color: #BF0426 ;
}

.button-unique-2:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #BF0426 ;
  border-radius: 10px;
  z-index: -2;
}

.button-unique-2:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color:#8C0303 ;
  transition: all .3s;
  border-radius: 10px;
  z-index: -1;
}

.button-unique-2:hover {
  color: var(--white-color);
}

.button-unique-2:hover:before {
  width: 100%;
}


.button-unique-1 {
  text-decoration: none;
  display: inline-block;
  padding: .75rem 1.55rem;
  border-radius: 10px;
  color: var(--white-color);
  text-transform: uppercase;
  font-size: 1rem;
  text-align:center ;
  align-items: center;
  font-family: 'Poppins';
  font-weight: 700;
  letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: none;
  background-color: #BF0426 ;
}

.button-unique-1:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:#BF0426 ;
  border-radius: 10px;
  z-index: -2;
}

.button-unique-1:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #8C0303 ;
  transition: all .3s;
  border-radius: 10px;
  z-index: -1;
}

.button-unique-1:hover {
  color: var(--white-color);
}

.button-unique-1:hover:before {
  width: 100%;
}

@media screen and (max-width: 575px) {
  
  .button-unique-1 {
    text-decoration: none;
    display: inline-block;
    padding: .55rem 1.5rem;
    border-radius: 10px;
    color: var(--white-color);
    text-transform: uppercase;
    font-size: 0.7rem;
    text-align:center ;
    align-items: center;
    font-family: 'Poppins';
    font-weight: 700;
    letter-spacing: .15rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: none;
    background-color: #BF0426 ;
  }

  .button-unique-1:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:#BF0426 ;
    border-radius: 10px;
    z-index: -2;
  }

  .button-unique-1:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #8C0303 ;
    transition: all .3s;
    border-radius: 10px;
    z-index: -1;
  }

  .button-unique-1:hover {
    color: var(--white-color);
  }

  .button-unique-1:hover:before {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  
  .button-unique-1 {
    text-decoration: none;
    display: grid;
    padding: .4rem 0.5rem;
    border-radius: 10px;
    color: var(--white-color);
    text-transform: uppercase;
    font-size: 0.6rem;
    text-align:center ;
    align-items: center;
    font-family: 'Poppins';
    font-weight: 700;
    letter-spacing: .1rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: none;
    background-color: #BF0426 ;
    text-align: center;
  }

  .button-unique-1:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:#BF0426 ;
    border-radius: 10px;
    z-index: -2;
  }

  .button-unique-1:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #8C0303 ;
    transition: all .3s;
    border-radius: 10px;
    z-index: -1;
  }

  .button-unique-1:hover {
    color: var(--white-color);
  }

  .button-unique-1:hover:before {
    width: 100%;
  }
}