@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.wrapper-svg {
	font-family: "Russo One", sans-serif;
	width: 100%; 
    height: 100%;
    margin-bottom: -60px;
}

.wrapper-svg text {
	animation: stroke 3s infinite alternate;
	stroke-width: 2;
	stroke: #365FA0;
	font-size: 100px;
    margin: 0; 

}

@keyframes stroke {
	0%   {
		fill: rgba(72,138,204,0); 
        stroke: rgba(54,95,160,1);
		stroke-dashoffset: 25%; 
        stroke-dasharray: 0 50%; 
        stroke-width: 2;
	}
	70%  {
        fill: rgba(72,138,204,0); 
        stroke: rgba(54,95,160,1);
    }
	80%  {
        fill: rgba(72,138,204,0); 
        stroke: rgba(54,95,160,1); 
        stroke-width: 3; 
    }
	100% {
		fill: rgba(72,138,204,1); 
        stroke: rgba(54,95,160,0);
		stroke-dashoffset: -25%; 
        stroke-dasharray: 50% 0; 
        stroke-width: 0;
	}
}

.wrapper {
    background-color: #FFFFFF;
    margin: 0; 
    top: 0;
    margin-bottom: 0;
};



@media screen and (max-width: 991px){
    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
    .wrapper-svg {
        font-family: "Russo One", sans-serif;
        width: 100%; 
        height: 100%;
        margin-bottom: -70px;
    }
    .wrapper-svg text {
        animation: stroke 3s infinite alternate;
        stroke-width: 2;
        stroke: #365FA0;
        font-size: 75px;
    }
}

@media screen and (max-width: 768px){
    .wrapper-svg {
        font-family: "Russo One", sans-serif;
        width: 100%; 
        height: 100%;
        margin-bottom: -106px;
    }
    .wrapper-svg text {
        animation: stroke 3s infinite alternate;
        stroke-width: 2;
        stroke: #365FA0;
        font-size: 50px;
    }
}