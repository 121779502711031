.company-description{
  background-image: url('../../assets/companydescription/glc.jpg');
  color:white;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  overflow-x:hidden ;
}

.company-border-span{
  border: 10px solid #0d6efd;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  display: flex; /* Ezzel beállítjuk, hogy a tartalom középen legyen. */
  margin: 0 auto 0;
  justify-content: center; /* Vízszintesen középre igazítás. */
  align-items: center; /* Függőlegesen középre igazítás. */
}

.comapny-title{
  font-weight: 700;
  font-family: 'Poppins';  
}

.comapny-description{
  font-family: 'Lato';
  font-weight: 700;
}

@media screen and (max-width:991px){
  .company-description{
    background-image: url('../../assets/companydescription/glc.jpg');
    color:white;
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

}

@media screen and (max-width:768px){
  .company-description{
    background-image: url('../../assets/companydescription/glc.jpg');
    color:white;
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

}