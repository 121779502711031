.breadcrumb-component {
    height: 50vh;
    width: 100%;
    position: relative;
    justify-content: start;
    align-items: center;
    display: flex;
}

.breadcrumb-component::before {
    width: 100%;
    height: 100%;
    background-image: url('../../assets/breakdumb/bredcumbs-1.png') ;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    opacity: 0.2;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.breadcrumb-card{
    display: flex;
    align-items: center;
    justify-content: center;
}

.breadcrumb-title{
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 1;
    color: #1a1a1a;
    font-weight: 700;
    font-family: 'Poppins';
}

.breadcrumb{
    display: flex;
    justify-content: center;
    align-items: baseline;
    position: absolute;
    z-index: 1; /* Helyezd előre a háttérkép elé */
    margin: 0 auto 0;
}

.breadcrumb-list{
    color: #1a1a1a;
    font-weight: 700;
    font-family: 'Rubik';
    text-align: start;
}

.breadcrumb-link{
    text-decoration: none;
    color: #1a1a1a;
    font-weight: 700;
    font-family: 'Rubik';
}

@media screen and (max-width:991px){

    .breadcrumb-component {
        height: 50vh;
        width: 100%;
        position: relative;
    }
    
    .breadcrumb-component::before {
        width: 100%;
        height: 100%;
        background-image: url('../../assets/breakdumb/bredcumbs-1.png') ;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        opacity: 0.2;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

}