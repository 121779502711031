.first-title{
    font-family: 'Poppins';
}

.privacy-title{
    font-weight: bolder;
    font-family: 'Poppins';
}

.privacy-description{
    text-align: justify;
    text-justify: inter-word;
    color: #000000 ;
    font-size: 19px;
    font-family: "Rubik";
}

.privacy-bold{
    font-weight: bolder;
    color: #000000 ;
    font-family: 'Poppins';
}

.privacy-list{
    font-size: 18px;
    font-family: "Rubik";
    color: #000000 ;
}

@media screen and (max-width:991px){
    .table{
        width: 100%;
        height: 100%;
        align-items: center;
    }
}