.contact{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow-x:hidden ;
}

.contact-text-group{
    display: block;
    width: 80%;
    float: right;
}

.contact-title{
    color: #1a1a1a;
    font-weight: 700;
    font-family: 'Poppins';
}

#contact-img{
    border: 13px 13px 13px 13px;
}

.contact-description{
    text-align: justify;
    text-justify: inter-word;
    float:right;
    font-family: "Rubik";
    font-weight: 400 ;
    color: #817F91 ;
    /*letter-spacing: -0.24px;*/
}

.contact-description1{
    text-align: justify;
    text-justify: inter-word;
    font-family: "Rubik";
    font-weight: 400 ;
    color: #817F91 ;
}

@media screen and (max-width:991px){
    .contact-text-group{
        display: block;
        width: 100%;
    }
    .contact-title{
        float: none;
        text-align: center;
    }
}