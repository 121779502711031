.car-import{
    height: 100%;
    min-height: 100vh ;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.car-import-desc{
    height: 100%;
    min-height: 100vh ;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

#car-import-img{
    border-radius: 20px;
}

.car-import-desc-hr{
    border: 2px solid var(--primary);

}

.car-import-hr{
    border: 2px solid var(--primary);
    width: 30%;
    margin: 0 auto 0;
}


.carimport-title{
    font-family: 'Poppins';
}

.carimport-description{
    font-family: "Rubik";
    font-weight: 400 ;
}

@media screen and (max-width: 991px){
    .car-import{
        height: 100%;
        min-height: 100% ;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .car-import-desc{
        height: 100%;
        min-height: 100% ;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .car-import-hr{
        border: 2px solid var(--primary);
        width: 50%;
        margin: 0 auto 0;
    }
}

@media screen and (max-width: 768px){
    .car-import{
        height: 100%;
        min-height: 100% ;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .car-import-desc{
        height: 100%;
        min-height: 100% ;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .car-import-hr{
        border: 2px solid var(--primary);
        width: 60%;
        margin: 0 auto 0;
    }
}