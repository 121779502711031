.contactform{
    min-height: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x:hidden ;
}

.contact-form-contents{
    background-color: #F9FBFC;
    width: 90%;
    border-radius: 15px 15px 15px 15px ;
}

.contact-map{
    display: flex;
}

.contactform-title-h2{
    font-family: 'Poppins';
    font-weight: 700;
    color: #1a1a1a;
}

.contactform-title{
    font-family: 'Poppins';
    font-weight: 700;
    color: #1a1a1a;
}

.contactform-hr{
    background-color: rgb(19, 19, 192);
    width: 30%;
    height: 10px;
    border-radius: 10px;
    margin: 0;
}

.contactform-addres{
    font-family: 'Poppins';
    font-weight: 700;
    color: #1a1a1a;
}

.addres-title{
    font-family: "Rubik";
    font-weight: 400;
    color:rgb(129, 127, 145) ;
}

.contactform-lab{
    font-family: "Rubik";
    font-weight: 400;
    color:rgb(129, 127, 145) ;
}

@media screen and ( max-width:991px ){
    .contact-form-contents{
        background-color: #F9FBFC;
        width: 95%;
        border-radius: 15px 15px 15px 15px ;
    }    
    .contactform-title-h2{
        text-align: center;
    }
    .contactform-hr{
        margin: 0 auto 0;
    }
    .contactform-title{
        text-align: center;
    }
    #contactform-location{
        text-align: center;
    }
    #contactform-phone{
        text-align: center;
    }
    #contactform-email{
        text-align: center;
    }
    .contact-social-media{
        text-align: center;
    }
    #contacts-icons{
        margin: 0 auto 0;
    }
    .btn-form{
        margin: 0 auto 0;
        text-align: center;
    }
}