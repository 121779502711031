.anaccordion{
    width: 100%;
    background-color: #4070f3;
    clip-path: polygon(0 0, 190% 33%, 100% 100%, 0% 100%);
    height:  30rem;
    overflow: hidden;
    display: flex;
    overflow-x:hidden ;
    align-items: center;
    justify-content: center;
}

.accordion-card{
    justify-content: center;
    transform: translateY(20px);
    align-items: center;
    display: block;
    width: 70% ;
    margin: 0 auto 0;
}

@media screen and (max-width:789px){
    .accordion-card{
        transform: translateY(20px);
        width: 100% ;
    }
}