.sidebars {
    height: 100%;
    position: fixed;
    display: flex;
}



@media screen and (max-width:1240px) {
    .sidebars{
        height: auto;
        display: block;
        position: absolute;
    }

    
}