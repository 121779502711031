.rent-car{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x:hidden ;
}

.rent-carddesh{
    display: block;
    width: 80%;
}

.rentcar-description{
    text-align: justify;
    text-justify: inter-word;
    font-family: "Rubik";
    font-weight: 400 ;
    color: #817F91 ;
}
 
.rentcar-image{
    background-image: url("../../assets/decoration/decoration-star.svg");
    width: 100%;
}

.rent-car-title{
    color: #1a1a1a;
    font-weight: 700;
    font-family: 'Poppins';
}

@media screen and (max-width:992px){
    .rent-carddesh{
        display: block;
        width: 100%;
    }
    .rent-car-title{
        text-align: center;
    }
    .btn-group{
        margin: 0 auto 0;
    }
}