#carbuy-title{
    color: #1a1a1a;
    font-weight: 700;
    font-family: 'Poppins';
}

#carchange-title{
    color: #1a1a1a;
    font-weight: 700;
    font-family: 'Poppins';
}

