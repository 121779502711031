.about-description{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x:hidden ;
}

.aboutus{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x:hidden ;
}

#about-title{
    color: #1a1a1a;
    font-weight: 700;
    font-family: 'Poppins';
}

#about-title1{
    color: #1a1a1a;
    font-weight: 700;
    font-family: 'Poppins';
}

@media screen and (max-width:991px){
    .button-aboutus-groupd{
        margin: 0 auto 0;
        text-align: center;
    }
}