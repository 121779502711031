.hero-image{
    background-image: url("../../assets/decoration/decoration-star.svg");
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero {
    color: white;
    height: 100vh;
    width: 100%;
    position: relative;
}

.hero:before {
    content: '';
    background: url('../../assets/hero/2021-mercedes-benz-e-450-4matic-coupe-47-1628717335.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.hero-hr{
    margin: 0 auto 0;
    border: 3px solid #BF0426;
    width: 40%;
    border-radius: 20px 20px 20px 20px;
}

.hero-title{
    text-align: center;
    color: white;
    font-weight: bold;
    font-family: 'Poppins';
}

.hero-description{
    text-align: center;
    font-family: "Rubik";
    font-weight: 400 ;
}

