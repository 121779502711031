#img-fulladministration{
    border-radius: 13px 13px 13px 13px;
}

.full-administration-cards{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x:hidden ;
}

#consulation-title{
    color: #1a1a1a;
    font-weight: 700;
    font-family: 'Poppins';
}

#fulladd-title1{
    color: #1a1a1a;
    font-weight: 700;
    font-family: 'Poppins';
}
.fulladministration{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x:hidden ;
}

.full-add-description{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x:hidden ;
}

.consulation{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x:hidden ;
}