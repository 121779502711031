
.rentcar-title{
    font-family: "Poppins";
    font-weight: 700 ;
    color: #1a1a1a;
    text-align: start;
}

.rentcar-title-category{
    font-family: "Poppins";
    font-weight: 700 ;
    color: #1a1a1a;
}

.rentcar-price{
    color: #1a1a1a;
    font-family: "Rubik";
    font-weight: 400;
}
.rentcar-day{
    font-family: "Poppins";
    font-weight: 700 ;
    color: #1a1a1a;
}

.cardimg-rent{
    min-height: 100%;
    height: 100%;
    max-height: 330px;
}

.card-desc-group{
    justify-content: center;
    align-items: center;
}

.card-category:hover{
    transform: scale(1.01);
    transition: 0.4s ease-in-out;

}

#card-category22:hover{
    border: 1px solid var(--primary);
    border-radius: 15px 15px 15px 15px;
}